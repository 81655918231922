<template>
  <div class="position-relative pb-1">
    <ColorForm ref="formRef" :formData="formData" title="Edit Color" action="Update"/>
  </div>
</template>

<script>
import ColorForm from "@/views/components/colors/ColorForm";
import ColorService from "@/services/ColorService";

export default {
  name: "Edit",
  components: {
    ColorForm
  },
  data() {
    return {
      formData: {
        id: this.$route.params.color_id,
        type: '',
        name: '',
        code: '',
        image: undefined,
        imageThumbnail: null,
        defaultImageThumbnail: null,
      },
      ColorService: new ColorService(),
      baseBackUrl: process.env.VUE_APP_API_URL
    }
  },
  methods: {
    getColor() {
      this.$refs.formRef.loading = true
      this.ColorService.show(this.formData.id).then(res => {
        this.$refs.formRef.loading = false
        this.formData.type = res.type
        this.formData.name = res.name
        this.formData.code = res.code
        if (res.media[0])
          this.formData.imageThumbnail = res.media[0].original_url
          this.formData.defaultImageThumbnail = res.media[0].original_url
      }, err => {
        this.$refs.formRef.loading = false
      })
    }
  },
  mounted() {
    this.getColor()
  }
}
</script>

<style scoped>

</style>
